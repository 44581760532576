import { Link } from 'gatsby';
import tw from 'twin.macro'
import React from 'react';

const TextBanner = ({ type }) => {
  const isHospitality = type === 'hospitality';
  const bg = !isHospitality ? tw`bg-[#FF4D00]` : tw`bg-[#1f2c3a]`;
  return (
    <div css={[
      tw`text-sm text-white flex flex-wrap justify-center items-center p-1`,
      bg
    ]}>
      Staffy offers {type} shifts as well
      <Link tw='text-[#FF4D00] bg-[#FFF] px-4 ml-3' to={isHospitality ? `/hospitality/start-earning` : '/start-earning'}>
        <small>Explore Now</small>
      </Link>
    </div>
  );
};

export default TextBanner;
